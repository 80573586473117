<template>
    <div>
        <div class="input-icon" @click="toggleDropdown()">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 11.2999C15.5376 11.2999 18 8.83749 18 5.79993C18 2.76236 15.5376 0.299927 12.5 0.299927C9.46243 0.299927 7 2.76236 7 5.79993C7 8.83749 9.46243 11.2999 12.5 11.2999Z" fill="#157FFB"/>
            <path d="M0 22.7C0 23.2 0.300024 23.7 0.900024 23.7C0.900024 23.7 0.9 23.7 1 23.7H24C24.5 23.7 24.9 23.2999 24.9 22.7999C24.9 22.7999 24.9 22.8 24.9 22.7C24.5 17.4 21.1 13.2 12.5 13.2C3.8 13.3 0.4 17.4 0 22.7Z" fill="#157FFB"/>
            </svg>
        </div>
        <div class="input-with-icon">
            <div class="children">
                <label class="form-label">Viaggiatori</label>
                <b-dropdown variant="transparent" id="dropdownOccupancy" ref="dropdownOccupancy" :text="children.length.toString()">
                    <b-dropdown-form>
                        <b-form-group @submit.stop.prevent>
                            <div class="occupancy-items">
                                <div class="occupancy-item">
                                    <div>
                                        Adulti
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span @click="removeAdult" class="square-icon">-</span>
                                        <strong>{{adults}}</strong>
                                        <span @click="addAdult" class="square-icon">+</span>
                                    </div>
                                </div>

                                <div class="occupancy-item">
                                    <div>
                                        Bambini
                                    </div>
                                    <div>
                                        <span @click="removeChild" class="square-icon">-</span>
                                        <strong>{{children.length}}</strong>
                                        <span @click="addChild" class="square-icon">+</span>
                                    </div>
                                </div>  
                                
                                <div class="occupancy-item">
                                    <div>
                                        <p v-if="children.length">
                                            <strong>Età dei bambini il giorno del check-in</strong><br>
                                        </p>
                                        <div v-for="(m,index) in children" v-bind:key="index" class="occupancy-age">
                                            <span @click="removeSingleChild(index)">x</span>
                                            <span class="nowrap">Bambino {{index+1}}</span>
                                            <b-form-select :options="options" :value="m" @change="value => {setAge(value,index)}" />
                                        </div>
                                    </div>
                                </div>
                                <div class="occupancy-item">
                                    <b-button class="d-block w-100 text-center" variant="secondary" @click="$refs.dropdownOccupancy.hide(true)">Conferma</b-button>
                                </div>
                            </div>
                        </b-form-group>
                    </b-dropdown-form>
                    <template v-slot:button-content>
                        <div>
                        {{adults}} <span v-if="adults==1">Adulto</span><span v-else>Adulti</span>
                        -
                        {{children.length}} <span v-if="children.length==1">Bambino</span><span v-else>Bambini</span>
                        </div>
                    </template>
                </b-dropdown>
            </div>        
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props:{
        searchMaxChildren: Number,
        searchMaxAdults: Number,
        elementSlug: String
    },
    computed: mapGetters({
        children:   'getChildren',
        adults:     'getAdults'
    }),
    data() {
        return {
            options: [
                {value:17, text:'17 anni'},
                {value:16, text:'16 anni'},
                {value:15, text:'15 anni'},
                {value:14, text:'14 anni'},
                {value:13, text:'13 anni'},
                {value:12, text:'12 anni'},
                {value:11, text:'11 anni'},
                {value:10, text:'10 anni'},
                {value:9,  text:'9 anni'},
                {value:8,  text:'8 anni'},
                {value:7,  text:'7 anni'},
                {value:6,  text:'6 anni'},
                {value:5,  text:'5 anni'},
                {value:4,  text:'4 anni'},
                {value:3,  text:'3 anni'},
                {value:2,  text:'2 anni'},
                {value:1,  text:'1 anno'},
                {value:0,  text:'0 anni'}
            ],
            open: false
        }
    },
    methods:
    {
        addChild: function() {
            if(this.children.length < this.searchMaxChildren)
            {
                let children = this.children
                children.push(17)
                this.mixinSendMutation('setChildren',children)
            }
        },
        removeChild: function(index) {
            if(this.children.length > 0)
            {
                let children = this.children
                children.splice(index, 1)
                this.mixinSendMutation('setChildren',children)
            }
        },
        removeSingleChild(index)
        {
            let children = this.children
            children.splice(index, 1)
            //this.mixinSendMutation('setChildren',children)
            this.mixinSendMutation('setChildren',[])
            this.mixinSendMutation('setChildren',children)
            this.$refs.dropdownOccupancy.show(true)
            return false
        },
        setAge(value,index)
        {
            let children = this.children
            children[index] = value
            this.mixinSendMutation('setChildren',[])
            this.mixinSendMutation('setChildren',children)
        },
        addAdult: function() {
            if (this.adults < this.searchMaxAdults)
            {
                this.mixinSendMutation('setAdults',this.adults+1)
            }
        },
        removeAdult: function() {
            if (this.adults > 1)
            {
                this.mixinSendMutation('setAdults',this.adults-1)
            }
        },
        toggleDropdown() {
            if(this.open)
            {
                this.$refs.dropdownOccupancy.hide(true)
            }
            else
            {
                this.$refs.dropdownOccupancy.show(true)
            }
            this.open = !this.open
        }

    },
    mounted()
    {
        this.$root.$on('bv::dropdown::show', bvEvent => {
            if(bvEvent.componentId == 'dropdownOccupancy')
            {
                this.open = true
            }
        })
        this.$root.$on('bv::dropdown::hide', bvEvent => {
            if(bvEvent.componentId == 'dropdownOccupancy')
            {
                this.open = false
            }
        })
    }
}
</script>

<style lang="scss">

    .children
    {
        .form-group
        {
            width: 100%;
        }
        label
        {
            white-space:nowrap;
            //margin-top:0.5rem;
            font-size:80%;
        }
        a
        {
            color:$white !important;
        }
        .dropdown
        {
            background:$white;
            border-radius: $border-radius;
            display:block;
            > .btn
            {
                display: flex;
                width: 100%;
                text-align: left;
                justify-content: space-between;
                align-items: center;
                font-weight: normal;
                padding-left: 0;
                box-shadow: none !important;
                max-width: 100% !important;
            }
        }
        .dropdown-menu.show
        {
            transform:translateY(-10px) !important;
            left: 0;
            right: 0;
            top: 100% !important;
            border:0px;
            border-top:0;
            box-shadow:$box-shadow;
        }
        .occupancy-items
        {
            //margin-top:$grid-gutter-width/4;
        }
        .occupancy-item
        {
            display:flex;
            justify-content: space-between;
            align-items: center;
            padding:$grid-gutter-width/4 $grid-gutter-width/4;
            &:not(:first-child)
            {
                border-top:1px solid $medium-gray;
            }
            &:last-child
            {
                padding-bottom:0;
                border-top:0;
                padding-top:0;
            }
            .square-icon
            {
                @include customize-color("cta");
            }
        }
        .occupancy-age
        {
            display:flex;
            justify-content:space-between;
            align-items: center;
            select
            {
                padding:0 !important;
                max-width: 100px;
                box-shadow: none;
            }
        }

        button
        {
            border:0;
        }
    }

    .mobile-search-on
    {
        #dropdownOccupancy ul
        {
            position:relative !important;
            margin-left:-3.5rem;
            width:calc(100% + 3.5rem);
        }
        #search-form
        {
            max-height:80vh;
            overflow:scroll;
            /*
            position: relative;
            &:after
            {
                content:"";
                height:15px;
                width:100%;
                position:absolute;
                bottom:0;
                left:0;
                right:0;
                background:linear-gradient(0deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%);
            }
            */
        }
    }

</style>