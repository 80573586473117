<template>

    <div id="typeahead-container">
        <div id="typeahead-back" class="input-icon">
            <Spinner size="sm" v-if="loading" />
            <svg v-else width="21" height="28" viewBox="0 0 21 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.608 0.856018C7.85232 0.857872 5.21005 1.9533 3.26141 3.90175C1.31278 5.8502 0.217085 8.49236 0.214966 11.248C0.214966 16.98 6.26497 23.494 10.608 27.228C14.95 23.494 21 16.98 21 11.248C20.9979 8.49236 19.9022 5.8502 17.9535 3.90175C16.0049 1.9533 13.3626 0.857872 10.607 0.856018H10.608ZM10.607 13.825C9.83977 13.825 9.08982 13.5975 8.45192 13.1713C7.81402 12.745 7.31683 12.1392 7.02324 11.4305C6.72965 10.7217 6.65283 9.94171 6.80251 9.18925C6.95218 8.4368 7.32162 7.74564 7.86411 7.20316C8.40659 6.66067 9.09777 6.29123 9.85022 6.14156C10.6027 5.99188 11.3826 6.0687 12.0914 6.36229C12.8002 6.65588 13.406 7.15306 13.8322 7.79095C14.2585 8.42885 14.486 9.17882 14.486 9.94601C14.4857 10.9747 14.0769 11.9612 13.3495 12.6886C12.6222 13.416 11.6357 13.8247 10.607 13.825Z" fill="#0071BC" />
            </svg>
        </div>
        <div class="input-with-icon">
            <label v-if="loading" class="form-label" style="color:red">attendi e scegli</label>
            <label v-else class="form-label">Dove / Nome hotel</label>
            <vue-bootstrap-typeahead
                v-model="addressSearch"
                :key="geo.label"
                :inputClass="'autocomplete'"
                :placeholder="(geo.label == '' ? 'Inserisci una località' : geo.label)"
                :data="suggestions"
                :serializer="s => s.label"
                :maxMatches="maxMatches"
                :minMatchingChars="minMatchingChars"
                @hit="getGeocode($event)"
            />
        </div>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import VueBootstrapTypeahead from '../../vendor/vue-bootstrap-typeahead/src/components/VueBootstrapTypeahead'
    import Axios from 'axios'
    import _ from 'underscore'
    import Vue from "vue";
    import Spinner from '@/components/atoms/spinner'
    import Fuse from 'fuse.js'
    //import Vue from "vue";

    export default {
        name: 'autocomplete',
        components:{
            VueBootstrapTypeahead,
            Spinner,
        },
        props: {
            appId: String, // old auth
            appCode: String, // old auth
            apiKey: String, // new auth
            geocodeEndpoint: String,
            suggestionsEndpoint: String,
            searchByGeoEndpoint: String,
            searchInSalabamEndpoint: String,
            searchByPropertyEndpoint: String,
        },
        computed: mapGetters({
            latitude:   'getLatitude',
            longitude:  'getLongitude',
            matchLevel: 'getMatchLevel',
            geo: 'getGeo',
        }),
        data() {
            return {
                language: 'it',
                minMatchingChars: 0,
                minDigits: 3,
                maxMatches: 10,
                //debounceMs: Vue.prototype.$config.searchSettings.autocompleteDebounceMs,
                suggestionsObjects: {},
                suggestions: [],
                addressSearch: '',
                address: null,
                loading: false,
                query: '',
            }
        },
        methods: {
            what(event)
            {
                window.console.log('autocomplete.vue what event')
                window.console.log(event)
            },
            getSuggestions(query)
            {
                this.query = query

                if(query.length < this.minDigits) return

                // quando viene selezionato un suggerimento, l'autocomplete cambia e triggera le chiamate
                // se query corrisponde a quanto già presente in autocomplete, si tratta dell'evento generato
                // dalla "selezione" del suggerimento, quindi lo ignoro
                if(query == this.geo.label) return

                this.loading = true

                this.suggestions = [] // alla nuova ricerca, svuoto le suggestions

                if(this.$gtm) this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'userBehaviour',
                  action: 'getSuggestions',
                  label: query,
                  value: 1,
                });


                let _this = this

                // https://developer.here.com/documentation/geocoder/dev_guide/topics/resource-reverse-geocode.html
                let params = {
                    apikey: this.apiKey,
                    query: query,
                    language: this.language,
                    minresults: 1, // ignora tutto pur di avere almeno 1 risultato
                    maxresults: 5, // 5 default

                    token: Vue.prototype.$config.token, // local api
                }



              // imposto dinamicamente da confing i servizi da usare per la ricerca
              let searchServices = []


              // mio "loader" axios per gestire "il timeout"
              // (alcune chiamate vanno lunghe, le droppo così cmq si vedono subito i risultati del geocoder here.com)
              // https://github.com/axios/axios/issues/647
              const timedAxiosGet = (url, options = {}, timeout = 500) => {

                const abort = Axios.CancelToken.source()
                const id = setTimeout(
                    () => abort.cancel(`Timeout of ${timeout}ms`),
                    timeout
                )

                return Axios
                    .get(url, { cancelToken: abort.token, ...options })
                    .then(response => {
                      clearTimeout(id)
                      return response
                    })
                    .catch(function() { return false })

              } // timedAxiosGet


              // PROVIDERS RICERCA

              // here
              if(Vue.prototype.$config.searchSettings.useHere){
                searchServices.push(Axios.get(this.suggestionsEndpoint, {params}).catch(function() { return false }))
              }

              // geo
              if(Vue.prototype.$config.searchSettings.useGeo){

                //searchServices.push(Axios.get(this.searchByGeoEndpoint, {params}).catch(function() { return false }))
                searchServices.push(timedAxiosGet(this.searchByGeoEndpoint, {params}, Vue.prototype.$config.searchSettings.axiosTimeout))

              } // search use geo


              // inSalabam
              if(Vue.prototype.$config.searchSettings.useInSalabam){

                //searchServices.push(Axios.get(this.searchInSalabamEndpoint, {params}).catch(function() { return false }))
                searchServices.push(timedAxiosGet(this.searchInSalabamEndpoint, {params}, Vue.prototype.$config.searchSettings.axiosTimeout))

              } // search use inSalabam


              // property:hotel quirinale | property:123
              // oppure attivo by default by vars
              let x = query.split(':')
              if(Vue.prototype.$config.searchSettings.useProperty || this.searchByPropertyEndpoint && x[0] == 'property'){
                searchServices.push(timedAxiosGet(this.searchByPropertyEndpoint, {params}, Vue.prototype.$config.searchSettings.axiosTimeoutByProperty))
              }


              // failsafe: se sono tutti disattivati uso here
              if(searchServices.length == 0) searchServices.push(Axios.get(this.suggestionsEndpoint, {params}).catch(function() { return false }))



              return Axios.all(searchServices)
                .then(function(results){

                  // riceve tutti i risultati dei vari endpoints in un'unica chiamata

                  let suggestions = []

                  results.forEach(result => {

                    if(result.data && result.data.results){
                      // geo, inSalabam

                      result.data.results.forEach(suggestion => {
                        if(suggestion.countryCode == 'CUB' || suggestion.countryCode == 'CU') return false // escludo cuba
                        suggestions.push(suggestion)
                      })

                    } // if result.data.results


                    if(result.data && result.data.suggestions){
                      // here.com

                      let i = 0
                      result.data.suggestions.forEach(suggestion => {

                        if(suggestion.countryCode == 'CUB') return // escludo cuba

                        i++

                        let tempLabel = ''
                        let suggestionSorting = 0; // DESC

                        if(suggestion.matchLevel){
                          switch(suggestion.matchLevel){

                            case 'state':
                              suggestionSorting = 10;
                              tempLabel = `Regione ${suggestion.address.state}`
                              break

                            case 'county':
                              suggestionSorting = 90;
                              tempLabel = `Provincia di ${suggestion.address.county}`
                              if(suggestion.address.state) tempLabel += ', ' + suggestion.address.state
                              break

                            case 'city':
                              suggestionSorting = 100;
                              tempLabel = `Città di ${suggestion.address.city}`
                              if(suggestion.address.state) tempLabel += ', ' + suggestion.address.state
                              break

                            case 'district':
                              suggestionSorting = 50;
                              tempLabel = `Quartiere ${suggestion.address.district}, ${suggestion.address.city}`
                              break

                            case 'street':
                              return // inibito street
                              /*suggestionSorting = 20;
                              //tempLabel += 'Vicino '
                              tempLabel = `Vicino ${suggestion.address.street}, ${suggestion.address.city}`
                              suggestion.matchLevel = 'circle' // forza ricerca per raggio su backend
                              break*/

                            case 'country':
                              tempLabel = suggestion.address.country
                              break

                            default:

                          }

                          suggestion.name = suggestion.address[suggestion.matchLevel]

                        }

                        if(suggestion.countryCode == 'ITA'){
                          suggestionSorting += 100 // prima ITA (principale mercato)
                        } else {
                          tempLabel += ' (' + suggestion.address.country + ')'
                        }

                        // se si cambia la posizione di label
                        // o la struttura di suggestion
                        // cambiare il serializer dell'autocomplete
                        suggestion.label = tempLabel

                        // sottraendo i faccio si che a parità di suggestionSorting
                        // vengano comunque mostrati i suggerimenti secondo l'ordine ritornato dall'api
                        suggestion.sorting = suggestionSorting - i

                        suggestions.push(suggestion)

                      })

                    } // if result.data.suggestions (here.com)


                  }) // each results


                  const options = {
                    // isCaseSensitive: false,
                    // includeScore: false,
                    // shouldSort: true,
                    // includeMatches: false,
                    // findAllMatches: false,
                    minMatchCharLength: 3,
                    // location: 0,
                    threshold: 0.3,
                    // distance: 100,
                    // useExtendedSearch: false,
                    // ignoreLocation: false,
                    // ignoreFieldNorm: false,
                    // fieldNormWeight: 1,
                    keys: [
                      {
                        name: "address.city",
                        weight: 3
                      },
                      {
                        name: "name",
                        weight: 2
                      },
                      "label",
                    ]
                  };

                  let fuse = new Fuse(suggestions, options)

                  let fused = fuse.search(query)

                  suggestions = []
                  for(let i=0; i < fused.length; i++){
                    suggestions.push(fused[i].item)
                  }



                  if(suggestions.length == 0){

                    suggestions.push({
                      type: 'msg',
                      name: 'nessun risultato',
                      label: 'nessun risultato',
                    })


                    if(_this.$gtm) _this.$gtm.trackEvent({
                        event: 'customEvent',
                        category: 'search',
                        action: 'noSuggestions',
                        label: _this.query,
                        value: 0,
                      });

                    if(window.clarity) window.clarity("set", "search", "noSuggestions")


                  }

                  _this.suggestions = suggestions

                  _this.loading = false


                }) // axios then


            },
            getGeocode(suggestion)
            {

                if(this.$gtm) this.$gtm.trackEvent({
                    event: 'customEvent',
                    category: 'userBehaviour',
                    action: 'getGeocode',
                    label: suggestion.label,
                    value: 1,
                });

                this.address = suggestion.label

                if(suggestion.type == 'msg'){
                  // es. suggestion "nessun risultato"
                  // this.mixinSendMutation('setGeo', {}) // svuotando geo "cerca" non si disattiva
                  return
                }


                if(
                       suggestion.type == 'geo'
                    || suggestion.type == 'inSalabam'
                    || suggestion.type == 'property'
                ){
                  this.mixinSendMutation('setGeo', suggestion.geo)
                  return
                }

                // gli altri sono risultati del geocoder, da interrogare per le coordinate

                let _this = this
                var params = {
                    apikey: this.apiKey,
                    language: this.language,
                    jsonattributes: 1,
                    get: 9,
                    locationid:
                        this.suggestions
                        .filter(function(suggestion) {
                            return suggestion.label === _this.address
                        })[0].locationId
                }

                return Axios
                    .get(this.geocodeEndpoint, {params})
                    .then((r) => {
                        this.mixinSendMutation('setGeo',{
                            latitude:   r.data.response.view[0].result[0].location.displayPosition.latitude,
                            longitude:  r.data.response.view[0].result[0].location.displayPosition.longitude,
                            matchLevel: r.data.response.view[0].result[0].matchLevel,
                            label:   _this.address
                        })
                    }, (error) => {
                        window.console.log('getGeocode error')
                        window.console.log(error)
                    })
            }
        },
        watch: {
            addressSearch:
                _.debounce(function(addr) {
                    let str = addr.replace(/\s\s+/g, ' ') //replace multiple spaces with single space
                    str = str.trim() // trim single spaces
                    this.getSuggestions(str)
                }, 650)
        },
        mounted() {
            let input = document.getElementsByClassName('autocomplete')[0]
            input.onfocus = function(e){
                e.target.value = ' '
                return false
            }
        }

    }
</script>

<style lang="scss">

    #typeahead-container
    {

        .spinner-wrapper
        {
          width:7px;
          margin: 0 0.75rem;
          .spinner
          {
            transform:scale(0.7) translateY(4px) translateX(-1px);
            transform-origin:center center;
          }
        }

        position:relative;
        width:100%;
        &:after
        {
            content:"";
            position:absolute;
            z-index: 4;
            height:20px;
            width:24px;
            right:0;
            top:16px;
            height:14px;
            background:$white;
        }
        input
        {
            background-image:none !important;
            padding-right:10px;
        }
        .vbt-autcomplete-list
        {
            font-size:$font-size-sm;
            @include media-breakpoint-up(lg)
            {
                font-size:$font-size-base;
                border-radius:18px;
            }
        }
        .list-group-item-action
        {
            border-color:transparent;
        }
        .list-group-item-action.active
        {
            @include customize-background-color("secondary");
            border-color:transparent;
        }
    }

    @include media-breakpoint-down(md)
    {
        .list-group-item
        {
            padding: 0.2rem .95rem !important;
            border:0 !important;
            &:first-child
            {
                padding-top:0.7rem !important;
            }
            &:last-child
            {
                padding-bottom:0.4rem !important;
            }
        }
    }
    @include media-breakpoint-up(lg)
    {
        .list-group-item
        {
            font-size: 14px;
        }
    }
    @include media-breakpoint-up(xl)
    {
        .list-group-item
        {
            font-size: 15px;
        }
    }
    #typeahead-container:after
    {
        opacity:0;
    }
    /*
    #typeahead-container:after,
    #typeahead-container:before
    {
        display: none !important;
    }
    #typeahead-container
    {
        *
        {background:none !important;}
    }
    */
</style>