<template>
    <div>
        <div id="datepicker-container">

            <label v-if="checkin && !checkout" class="form-label" style="color:red">seleziona anche checkout</label>
            <label v-else class="form-label">Quando</label>

            <div class="input-icon">
                <svg 
                    @click="toggleDatepicker()"
                    width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.60077 0.799927V3.29993H2.10077C1.40077 3.29993 0.900781 3.89995 0.800781 4.59995V25.8999C0.800781 26.5999 1.40077 27.1 2.10077 27.2H24.6008C25.3008 27.2 25.8008 26.5999 25.9008 25.8999V4.59995C25.9008 3.89995 25.3008 3.39993 24.6008 3.29993H22.1008V0.799927H18.3008V3.29993H8.30078V0.799927H4.60077ZM3.40082 10.7999H23.4008V24.5999H3.40082V10.7999Z" fill="#157FFB"/>
                    <path d="M9.90039 13H5.90039V17H9.90039V13Z" />
                </svg>
            </div>
            <div class="input-with-icon">
                <b-form-input 
                    class="datepicker-trigger"
                    type="text"
                    :id="elementSlug+'-datepicker-trigger'"
                    :value="formatDates(checkin, checkout)"
                    :placeholder="'Scegli le date'"
                    :key="elementSlug+'-datepicker'"
                    :ref="elementSlug+'-datepicker'"
                />
            </div>
            <b-button id="lock-14-alert" v-b-popover.top="'Attenzione! Le prenotazioni per domani sono permesse solo fino alle 14:00 di oggi.'">&nbsp;</b-button>
        </div>
        <b-col cols="12" class="picker-wrapper">
            <AirbnbStyleDatepicker
                :min-date="checkinRange.min()"
                :end-date="checkinRange.max()"
                :trigger-element-id="elementSlug+'-datepicker-trigger'"
                :mode="'range'"
                :months-to-show="1"
                :fullscreen-mobile="true"
                :date-one="checkin"
                :date-two="checkout"
                @date-one-selected="checkin => { this.sendCheckinDate(checkin) }"
                @date-two-selected="checkout => { this.sendCheckoutDate(checkout) }"
                :showShortcutsMenuTrigger="false"
                :mobileHeader="'Scegli checkin e checkout'"
                :trigger="trigger"
                :closeAfterSelect="true"
                :showActionButtons="false"
            />
            <div class="picker-alert">
              <b-alert show variant="warning" size="xs">
                Le <b>prenotazioni per il giorno successivo</b> possono essere effettuate <b>entro le ore 14</b>.
              </b-alert>
            </div>
        </b-col>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex'
import format from 'date-fns/format'
import it from 'date-fns/locale/it'

export default {
    props:{
        elementSlug: String
    },

    computed: mapGetters({
            checkin:   'getCheckin',
            checkout:  'getCheckout',
        },
    ),

    mounted()
    {
        let today = new Date()
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        this.tomorrow = tomorrow.getFullYear() + '-' + (tomorrow.getMonth()+1) + '-' + tomorrow.getDate()
    },

    data()
    {
        return {
            trigger: false,
            dateFormat: 'DD MMM YYYY',//'D MMM YYYY',
            tomorrow: '',
            checkinRange: {
                min: function(){
                    let today = new Date(),
                        tomorrow = new Date(today)

                        tomorrow.setDate(tomorrow.getDate() + Vue.prototype.$config.guiSettings.minDays)

                        if(today.getHours()>13)
                        {
                            tomorrow.setDate(tomorrow.getDate() + 1)
                        }

                    /* CORONAVIRUS */
                    let coronaMinCheckin = new Date(Vue.prototype.$config.guiSettings.minCheckin);
                    if(tomorrow < coronaMinCheckin) tomorrow = coronaMinCheckin
                    /* coronavirus */

                    let year = tomorrow.getFullYear(),
                        month = tomorrow.getMonth()+1,
                        day = tomorrow.getDate()
                    month = (month.toString().length < 2) ? '0'+month : month
                    day = (day.toString().length < 2) ? '0'+day : day

                    let date = year+'-'+month+'-'+day//2019-10-29
                    //window.console.log(date)
                    return date
                },
                max: function(){
                    let today = new Date(),
                        nextYear = new Date(today)
                    nextYear.setDate(nextYear.getDate() + 365)
                    let year = nextYear.getFullYear(),
                        month = nextYear.getMonth()+1,
                        day = nextYear.getDate()

                    month = (month.toString().length < 2) ? '0'+month : month
                    day = (day.toString().length < 2) ? '0'+day : day

                    let date = year+'-'+month+'-'+day//2019-10-29
                    //window.console.log(date)
                    return date
                }
            }          
        }
    },
    methods: {
            sendCheckinDate(checkin)
            {

                let dateCheckin = new Date(checkin)
                let dateCheckout = new Date(this.checkout)
                let nights = (dateCheckout.getTime() - dateCheckin.getTime())/ (1000 * 3600 * 24)

                if(checkin !== this.checkout)
                {
                    this.mixinSendMutation('setCheckin', checkin)
                    this.mixinSendMutation('setNights', nights)
                    return
                }

                let date = new Date(this.checkout)
                date.setDate(date.getDate() + 1)
                let newCheckout = date.getFullYear() + '-' + (date.getMonth() +1) + '-' + date.getDate()
                this.mixinSendMutation('setCheckout',newCheckout)
                this.mixinSendMutation('setCheckin',checkin)
                this.mixinSendMutation('setNights',1)
            },
            sendCheckoutDate(checkout)
            {

                // popover blocco ore 14
                if(this.checkin == this.tomorrow)
                {
                  let _this = this
                  _this.$root.$emit('bv::show::popover', 'lock-14-alert')
                  setTimeout(function(){
                    _this.$root.$emit('bv::hide::popover', 'lock-14-alert')
                  }, 8000)
                }


                let dateCheckin = new Date(this.checkin)
                let dateCheckout = new Date(checkout)
                let nights = (dateCheckout.getTime() - dateCheckin.getTime())/ (1000 * 3600 * 24)
                
                if(checkout !== this.checkin)
                {
                    this.mixinSendMutation('setCheckout',checkout)
                    this.mixinSendMutation('setNights',nights)
                    return
                }

                let date = new Date(checkout)
                date.setDate(date.getDate() + 1)
                let newCheckout = date.getFullYear() + '-' + (date.getMonth() +1) + '-' + date.getDate()
                this.mixinSendMutation('setCheckout', newCheckout)
                this.mixinSendMutation('setNights',1)
            },
            formatDates(checkin, checkout) {
                let formattedDates = ''
                if (checkin) {
                    formattedDates = format(checkin, this.dateFormat, {locale: it})
                }
                if (checkout) {
                    formattedDates += '*' + format(checkout, this.dateFormat, {locale: it})
                }
                formattedDates = formattedDates.replace(/ /g,'/')
                formattedDates = formattedDates.replace('*',' - ')
                return formattedDates
            },
            emitDate(date,event) {
                this.$emit(event,date)
            },
            toggleDatepicker()
            {
                this.trigger = true//!this.trigger
            }
        },
    }
</script>

<style lang="scss">
    
    
    
    

    .search-form-datepicker
    {
        #lock-14-alert
        {
            height: 1px;
            width:100%;
            overflow:hidden;
            margin:0;
            padding: 0;
            visibility: hidden;
            position:absolute;
            left:0;
            top:0;
        }
        #datepicker-container
        {
            position:relative;
        }
        .asd__wrapper
        {
            border-radius:$border-radius;
            box-shadow:$box-shadow;
            border-color:$light-gray;
            border-right:0px;
            border-bottom:0px;
            //top:0 !important;
        }

        //desktop
        .asd__wrapper--datepicker-open
        {

        }

        // mobile
        .asd__wrapper--full-screen
        {
            z-index:100;
            top:auto !important;
            border-top:1px solid $medium-gray;
            background:$light-gray;

            //border-top-left-radius:$border-radius-lg !important;
            //border-top-right-radius:$border-radius-lg !important;
            border-top:1px solid $medium-gray;
            padding-top:0.5rem;

            .asd__mobile-header
            {
                display:none;
            }
            .asd__month-table
            {
                background:transparent;
            }
        }
        .asd__action-buttons
        {
            margin-bottom:0;
            button{
                //font-weight:normal;
                font-size: $font-size-sm;
                padding:$input-btn-padding-y-sm $input-btn-padding-x-sm;
            };
            button:nth-child(1)
            {
                @include customize-background-color("primary");
                border-radius: $border-radius;
                color:$white;
                display:none;
            }
            button:nth-child(2)
            {
                box-shadow:$box-shadow;
                border-radius:$border-radius;
                display:block;
                width:calc(100% - 2rem);
                text-align:center;
                float: none;
                margin: 0 auto;
                right: auto;
                left: auto; 
                border: 2px solid transparent;
                padding: 1rem 1.95rem;
                font-size: 1rem;
                line-height: 1.5;
                margin-bottom:1rem;
                font-weight: normal;                   
                @include customize-background-color("secondary");
                border-radius: $border-radius;
                color:$white !important;
                transition:$transition-base;
                &:hover
                {
                    text-decoration:none;
                    //background-color:darken($secondary, 5%);
                }
            }
        }
        .asd__day--enabled
        {
            //border-radius:50%;
        }
        .asd__week td
        {
            border:0 !important;
        }
        .asd__month-name
        {
            font-weight:normal;
            font-size:14px;
            margin: 0 0 50px;
        }
        .asd__mobile-close
        {
            transform:scaleX(1.5);
            margin-right:12px;
            background-color:transparent;
            color:#999999;
        }
        .asd__change-month-button
        {
            background:transparent;
            button
            {
                display:flex;
                transition:$transition-base;
                height:37px;
                width:37px;
                border-radius: 50%;
                
            }
        }
    }

    .mobile-search-on .asd__wrapper--datepicker-open
    {
        @include media-breakpoint-down(sm)
        {
            top:70px !important;
            z-index: 200;
            border-radius: 0;
        }
        @include media-breakpoint-only(md)
        {
            position: relative !important;
            left: 0;
            right: 0;
            margin: 15px auto;
            top:0 !important;
            padding-top:0;
            width:calc(100% + 60px) !important;
            margin-left:-30px;
            padding-left:0px;
            .asd__month,
            .asd__datepicker-header
            {
                margin-left:15px;
            }
            .asd__datepicker-header
            {
                margin-right:15px;
            }
        }
    }

    /*
    #datepicker-container
    {
        position:relative;
        .input-icon
        {
            position:absolute;
            left:0;
            top:50%;
            transform: translateY(-50%);
        }
        .input-with-icon
        {
            position:relative;
            z-index:1;
        }
        .form-control
        {
            padding-left:0;
            text-indent:3.5rem;
        }
    }
    */

    #datepicker-container {
      //border:10px solid red;
    }

    .picker-wrapper {
      padding-left: 0;
      padding-right: 0;
      .picker-alert {
        .alert {
          padding: 6px 12px;
          margin-top: 12px;
          margin-bottom: 0;
          font-size: $font-size-sm;
          b {
            color: rgba($text, 0.8);
          }
        }
      }
      @include media-breakpoint-up(md) {
        height: 1px;
        overflow: visible;
        padding-left: 0;
        width: 100%;
        max-width: 300px;
        padding: 0;
        .asd__wrapper--datepicker-open {
          position: relative !important;
          top: 0 !important;
          box-shadow: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          &+.picker-alert {
            opacity: 1;
            position: relative;
            z-index: 102;
          }
        }
        .picker-alert {
          opacity: 0;
          background-color: $white;
          padding: 10px;
          padding-top: 20px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          .alert {
            margin-top: 0;
          }
        }
      }
    }
</style>