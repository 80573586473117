<template>
  <a
    tabindex="0"
    href="#"
    :class="textClasses"
    @mouseover="active = true"
    @mouseout="active = false"
  >
    <slot name="suggestion" v-bind="{ data: data, htmlText: htmlText }">
      <span v-if="data.type == 'geo'" class="vbst-icon vbst-icon-geo">
        <SvgAutGeo />
      </span>

      <span v-else-if="data.type == 'property'" class="vbst-icon vbst-icon-property">
        <SvgAutProperty />
      </span>

      <span v-else-if="data.type == 'inSalabam'" class="vbst-icon vbst-icon-insalabam">
        <SvgAutInSalabam />
      </span>

      <span v-else-if="data.type == 'msg'" class="vbst-icon vbst-icon-not-results">
        <SvgAutNotResults />
      </span>

      <span v-else class="vbst-icon vbst-icon-here">
        <SvgAutHere />
      </span>

      <span v-html="htmlText"></span>
    </slot>
  </a>
</template>

<script>
import SvgAutGeo from '@/components/svg/autocomplete-geo'
import SvgAutHere from '@/components/svg/autocomplete-here'
import SvgAutProperty from '@/components/svg/autocomplete-property'
import SvgAutInSalabam from '@/components/svg/autocomplete-insalabam'
import SvgAutNotResults from '@/components/svg/autocomplete-not-results'

export default {
  name: 'VueBootstrapTypeaheadListItem',

  components: {
    SvgAutGeo,
    SvgAutHere,
    SvgAutProperty,
    SvgAutInSalabam,
    SvgAutNotResults
  },

  props: {
    data: {},
    htmlText: {
      type: String
    },
    backgroundVariant: {
      type: String
    },
    textVariant: {
      type: String
    }
  },

  data() {
    return {
      active: false
    }
  },

  computed: {
    textClasses() {
      let classes = ''
      classes += this.active ? 'active' : ''
      classes += this.backgroundVariant ? ` bg-${this.backgroundVariant}` : ''
      classes += this.textVariant ? ` text-${this.textVariant}` : ''
      return `vbst-item list-group-item list-group-item-action ${classes}`
    }
  }
}
</script>

<style lang="scss">

  .vbst {
    $this: &;

    &-item {
      display: flex;
      align-items: center;
      padding: 0.5rem 1.25rem 0.5rem 0.75rem !important;
    }

    &-icon {
      margin-right: 5px;
      margin-top: 5px;

      svg {
        width: 35px;
        max-width: none;
      }

      &-geo {
        svg {
          height: 35px;
        }
      }
      &-here, &-property, &-insalabam {
        svg {
          height: 24px;
        }
      }

      &-not-results {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

</style>
