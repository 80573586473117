<template>
    <div class="search-form-fields">
        <div class="search-form-item search-form-autocomplete">
            <Autocomplete
                :appId="config.here.app_id"
                :appCode="config.here.app_code"
                :apiKey="config.here.apikey"

                :searchByGeoEndpoint="config.endpoints.searchByGeo"
                :searchInSalabamEndpoint="config.endpoints.searchInSalabam"
                :searchByPropertyEndpoint="config.endpoints.searchByProperty"

                :suggestionsEndpoint="config.here.suggestions_endpoint"
                :geocodeEndpoint="config.here.geocode_endpoint"
            />  
        </div>

        <div class="search-form-item search-form-datepicker">
            <Datepicker :elementSlug="elementSlug"/>
        </div>

        <div class="search-form-item search-form-occupancy">
            <div class="input-occupancy">
                <Occupancy :elementSlug="elementSlug" v-model="occupancy" :searchMaxAdults="config.guiSettings.searchMaxAdults" :searchMaxChildren="config.guiSettings.searchMaxChildren" />
            </div>
        </div>
        
        <div class="search-form-item search-form-submit">
            <b-button
                class="btn-search"
                :disabled="disableSearchButton() || (loading == true)"
                variant="secondary"
                v-on:click="$emit('submit-clicked')"
            >Cerca
            </b-button>
        </div>

        <div class="form-fields-block" v-if="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import Autocomplete from './autocomplete'
import Datepicker from './datepicker'
import Occupancy from './occupancy'
//import Spinner from '../../atoms/spinner'

export default {
    name: 'search-fields',
    components:
    {
        //Spinner,
        Autocomplete,
        Datepicker,
        Occupancy,
    },
    props: {
        offset: Number,
        elementSlug: String
    },
    computed:
    {
        loading: {
            get() { return (this.$parent.$parent.loading | this.$parent.$parent.appending) },
            set() {}
        },
        occupancy: {
            get() {
                if (this.$store.state.occupancy.adults > 0)
                {
                    return this.$store.state.occupancy
                }
                this.mixinSendMutation('setAdults',2)
                return this.$store.state.occupancy
            },
            set(value) { this.mixinSendMutation('setOccupancy',value) }
        },
        dates: {
            get() { return this.$store.state.dates },
            set(value) { this.mixinSendMutation('setDates',value) }
        },
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        },
        nightsOptions:{
            get() {
            let options = [
                { value: 0, text: 'Notti' }
            ]
            for (let a = 0; a < this.config.guiSettings.searchNights.length; a++) {
                let value = this.config.guiSettings.searchNights[a]
                let text = value==1 ? '1 notte' : value+' notti'
                options.push({
                value: value,
                text: text
                })
            }
            return options
            },
            set(value) { this.mixinSendMutation('setAdults', value) }
        }
    },
    data() {
        return {
            config: Vue.prototype.$config
        }
    },
    methods:{
        disableSearchButton()
        {
            //if (this.offset == -1) return true
            if (this.geo.latitude == 0) return true
            if (this.geo.longitude == 0) return true
            if (this.dates.checkin == '') return true
            if (this.dates.checkout == '') return true
            if (this.occupancy.adults == 0) return true
            //if (this.dates.nights == 0) return true
            this.$parent.$emit('search-form-is-valid')
            return false
        }
    }
}
</script>

<style lang="scss" scoped>
    .search-form-fields
    {
        position:relative;
    }
    .form-fields-block
    {
        position:absolute;
        width:100%;
        height:100%;
        background:transparent;
        z-index: 100;
    }
</style>